import { CommonModule } from '@angular/common';
import { Component, Input, OnInit } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatRadioModule } from '@angular/material/radio';
import { BaseInputFormFieldComponent } from '../base-input-form-field';

@Component({
	standalone: true,
	selector: 'boolean-radio-input-form-field',
	templateUrl: './boolean-radio-input-form-field.component.html',
	styleUrl: './boolean-radio-input-form-field.component.scss',
	imports: [CommonModule, FormsModule, ReactiveFormsModule, MatFormFieldModule, MatRadioModule]
})
export class BooleanRadioInputFormFieldComponent extends BaseInputFormFieldComponent implements OnInit {
	@Input()
	public trueLabel: string = 'Yes';

	@Input()
	public falseLabel: string = 'No';

	constructor() {
		super('A selection');
	}

	public ngOnInit(): void {}
}
