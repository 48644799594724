/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
export enum EventStatus {
    Preparation = 0,
    InProgress = 1,
    NotDone = 2,
    OnHold = 3,
    Stopped = 4,
    Completed = 5,
    EnteredInError = 6,
    Unknown = 7,
}

export const EventStatusDisplayMap : { [key in EventStatus] : string } = {
    [EventStatus.Preparation]: "Preparation",
    [EventStatus.InProgress]: "InProgress",
    [EventStatus.NotDone]: "NotDone",
    [EventStatus.OnHold]: "OnHold",
    [EventStatus.Stopped]: "Stopped",
    [EventStatus.Completed]: "Completed",
    [EventStatus.EnteredInError]: "EnteredInError",
    [EventStatus.Unknown]: "Unknown",
}
