<mat-card [ngClass]="{ disabled: disabled }">
	@if (disabled) {
		<div class="disabled-overlay"></div>
	}
	<mat-card-header>
		@if (title) {
			<mat-card-title style="padding-right: 36px"> {{ title }} </mat-card-title>
		}

		@if (title && headerButtonOptions) {
			<button class="header-button" mat-icon-button (click)="headerButtonClicked.emit()">
				<mat-icon [color]="headerButtonOptions.color">{{ headerButtonOptions.icon }}</mat-icon>
			</button>
		}
	</mat-card-header>
	<mat-card-content>
		<ng-content></ng-content>
	</mat-card-content>
	@if (noFooterContent) {
		<div class="no-footer-content"></div>
	} @else {
		<mat-card-footer>
			<ng-content select="[action-card-footer-content]"></ng-content>
		</mat-card-footer>
	}
</mat-card>
