import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';
import { RouterModule } from '@angular/router';
import { SignalRService } from '../../shared/services/signal-r.service';

@Component({
	standalone: true,
	changeDetection: ChangeDetectionStrategy.OnPush,
	selector: 'tenant',
	template: '<router-outlet />',
	styles: ['host {display: contents;}'],
	imports: [RouterModule]
})
export class AppTenantComponent implements OnInit {
	public constructor(private _signalRService: SignalRService) {}

	public ngOnInit(): void {
		this._signalRService.startListening();
	}
}
