/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
export enum QuestionnaireTitleApiEnum {
    AbdominalGuAssessment = 0,
    BloodDrawDetails = 1,
    CvsAssessment = 2,
    CadiacMonitorDetails = 3,
    CathFoleyDetails = 4,
    ChestAssessment = 5,
    ClinicalImpressionAbdominalPain = 6,
    ClinicalImpressionAllergy = 7,
    ClinicalImpressionAlteredMentalState = 8,
    ClinicalImpressionAnimalBite = 9,
    ClinicalImpressionAssault = 10,
    ClinicalImpressionAsthma = 11,
    ClinicalImpressionBurnOrSmokeInhalation = 12,
    ClinicalImpressionChest = 13,
    ClinicalImpressionCriticalCare = 14,
    ClinicalImpressionDizziness = 15,
    ClinicalImpressionDyspnea = 16,
    ClinicalImpressionEar = 17,
    ClinicalImpressionEye = 18,
    ClinicalImpressionFaceAndScalp = 19,
    ClinicalImpressionFall = 20,
    ClinicalImpressionFemaleGu = 21,
    ClinicalImpressionFlu = 22,
    ClinicalImpressionFoot = 23,
    ClinicalImpressionGiBleeding = 24,
    ClinicalImpressionHand = 25,
    ClinicalImpressionHead = 26,
    ClinicalImpressionHeadache = 27,
    ClinicalImpressionHip = 28,
    ClinicalImpressionLowerBack = 29,
    ClinicalImpressionLowerExtremityInjury = 30,
    ClinicalImpressionLowerExtremityProblem = 31,
    ClinicalImpressionMvc = 32,
    ClinicalImpressionMaleGu = 33,
    ClinicalImpressionMiscellaneous = 34,
    ClinicalImpressionMouth = 35,
    ClinicalImpressionMultipleTrauma = 36,
    ClinicalImpressionNvd = 37,
    ClinicalImpressionNeckUpperBack = 38,
    ClinicalImpressionNeuro = 39,
    ClinicalImpressionNose = 40,
    ClinicalImpressionPalpitations = 41,
    ClinicalImpressionPediatricAbdominal = 42,
    ClinicalImpressionPediatricIllness = 43,
    ClinicalImpressionPediatricInjury = 44,
    ClinicalImpressionPediatricWheezing = 45,
    ClinicalImpressionPlantar = 46,
    ClinicalImpressionPregnancy = 47,
    ClinicalImpressionPsychSuicideOverdose = 48,
    ClinicalImpressionSeizure = 49,
    ClinicalImpressionShoulder = 50,
    ClinicalImpressionSkin = 51,
    ClinicalImpressionSyncope = 52,
    ClinicalImpressionTrunk = 53,
    ClinicalImpressionUpperExtremitiesInjury = 54,
    ClinicalImpressionUpperExtremityProblems = 55,
    DomesticViolenceScreening = 56,
    EentAssessment = 57,
    EkgDetails = 58,
    EkgFindingsAndImpressions = 59,
    ExtremetiesAssessment = 60,
    FallScreening = 61,
    FamilyMemberHealthHistory = 62,
    FunctionalNutritionalAssessment = 63,
    FunctionalNutritionalScreening = 64,
    GeneralAssessment = 65,
    HeadNeckAssessment = 66,
    HistoryOfPresentIllnessAbdominal = 67,
    HistoryOfPresentIllnessAllergies = 68,
    HistoryOfPresentIllnessAlteredMentalState = 69,
    HistoryOfPresentIllnessAnimalBite = 70,
    HistoryOfPresentIllnessAssault = 71,
    HistoryOfPresentIllnessAsthma = 72,
    HistoryOfPresentIllnessBurnInhalation = 73,
    HistoryOfPresentIllnessCardiopulmonary = 74,
    HistoryOfPresentIllnessChest = 75,
    HistoryOfPresentIllnessCriticalCare = 76,
    HistoryOfPresentIllnessDizziness = 77,
    HistoryOfPresentIllnessDyspnea = 78,
    HistoryOfPresentIllnessEar = 79,
    HistoryOfPresentIllnessEye = 80,
    HistoryOfPresentIllnessFaceScalp = 81,
    HistoryOfPresentIllnessFall = 82,
    HistoryOfPresentIllnessFemaleGu = 83,
    HistoryOfPresentIllnessFlu = 84,
    HistoryOfPresentIllnessFoot = 85,
    HistoryOfPresentIllnessGiBleeding = 86,
    HistoryOfPresentIllnessHand = 87,
    HistoryOfPresentIllnessHead = 88,
    HistoryOfPresentIllnessHeadache = 89,
    HistoryOfPresentIllnessHip = 90,
    HistoryOfPresentIllnessLowerBack = 91,
    HistoryOfPresentIllnessLowerExtremityInjury = 92,
    HistoryOfPresentIllnessLowerExtremityProblem = 93,
    HistoryOfPresentIllnessMvc = 94,
    HistoryOfPresentIllnessMaleGu = 95,
    HistoryOfPresentIllnessMiscellaneous = 96,
    HistoryOfPresentIllnessMouth = 97,
    HistoryOfPresentIllnessMultipleTrauma = 98,
    HistoryOfPresentIllnessNvd = 99,
    HistoryOfPresentIllnessNeckUpperBack = 100,
    HistoryOfPresentIllnessNeuro = 101,
    HistoryOfPresentIllnessNose = 102,
    HistoryOfPresentIllnessPalpitations = 103,
    HistoryOfPresentIllnessPediatricAbdominal = 104,
    HistoryOfPresentIllnessPediatricIllness = 105,
    HistoryOfPresentIllnessPediatricInjury = 106,
    HistoryOfPresentIllnessPediatricWheezing = 107,
    HistoryOfPresentIllnessPlantar = 108,
    HistoryOfPresentIllnessPregnancy = 109,
    HistoryOfPresentIllnessPsychSuicideOverdose = 110,
    HistoryOfPresentIllnessSeizure = 111,
    HistoryOfPresentIllnessShoulder = 112,
    HistoryOfPresentIllnessSkin = 113,
    HistoryOfPresentIllnessSyncope = 114,
    HistoryOfPresentIllnessTrunk = 115,
    HistoryOfPresentIllnessUpperExtemityInjury = 116,
    HistoryOfPresentIllnessUpperExtemityProblem = 117,
    IvDetails = 118,
    InfectiousDiseaseScreening = 119,
    MentalHealthScreening = 120,
    NeurologicalAssessment = 121,
    NgTubeDetails = 122,
    NurseSecondaryAssessmentAbdominalPainNvd = 123,
    NurseSecondaryAssessmentAlcoholIntoxSubstanceAbuse = 124,
    NurseSecondaryAssessmentAllergy = 125,
    NurseSecondaryAssessmentAlteredMentalStatus = 126,
    NurseSecondaryAssessmentAnimalBite = 127,
    NurseSecondaryAssessmentAssault = 128,
    NurseSecondaryAssessmentBack = 129,
    NurseSecondaryAssessmentBurnInhilation = 130,
    NurseSecondaryAssessmentChest = 131,
    NurseSecondaryAssessmentDizziness = 132,
    NurseSecondaryAssessmentDyspneaAsthma = 133,
    NurseSecondaryAssessmentEarAndNose = 134,
    NurseSecondaryAssessmentEye = 135,
    NurseSecondaryAssessmentFemaleGu = 136,
    NurseSecondaryAssessmentFlu = 137,
    NurseSecondaryAssessmentGeneralMedicine = 138,
    NurseSecondaryAssessmentGeriatricFever = 139,
    NurseSecondaryAssessmentHead = 140,
    NurseSecondaryAssessmentHeadache = 141,
    NurseSecondaryAssessmentLowerExtremities = 142,
    NurseSecondaryAssessmentLowerExtremitiesProblem = 143,
    NurseSecondaryAssessmentMvc = 144,
    NurseSecondaryAssessmentMaleGu = 145,
    NurseSecondaryAssessmentMouth = 146,
    NurseSecondaryAssessmentNeuro = 147,
    NurseSecondaryAssessmentOverdose = 148,
    NurseSecondaryAssessmentPediatricAbdominal = 149,
    NurseSecondaryAssessmentPediatricFever = 150,
    NurseSecondaryAssessmentPediatricIllness = 151,
    NurseSecondaryAssessmentPediatricInjury = 152,
    NurseSecondaryAssessmentPediatricWheezing = 153,
    NurseSecondaryAssessmentPregnancy = 154,
    NurseSecondaryAssessmentPsych = 155,
    NurseSecondaryAssessmentSeizure = 156,
    NurseSecondaryAssessmentSkin = 157,
    NurseSecondaryAssessmentUpperExtremitiesInjury = 158,
    NurseSecondaryAssessmentUpperExtremityProblem = 159,
    NurseSecondaryAssessmentUpperRespiratory = 160,
    NurseTriage = 161,
    PastMedicalHistory = 162,
    PastSurgicalHistory = 163,
    PatientDischargeQuestionnaire = 164,
    PediatricMentalHealthScreening = 165,
    PhysicalExamAbdominal = 166,
    PhysicalExamAllergies = 167,
    PhysicalExamAlteredMentalState = 168,
    PhysicalExamAnimalBite = 169,
    PhysicalExamAssault = 170,
    PhysicalExamAsthma = 171,
    PhysicalExamBurnSmokeInhilation = 172,
    PhysicalExamCardiopulmonaryAssessment = 173,
    PhysicalExamChest = 174,
    PhysicalExamCriticalCare = 175,
    PhysicalExamDizziness = 176,
    PhysicalExamDyspnea = 177,
    PhysicalExamEar = 178,
    PhysicalExamEye = 179,
    PhysicalExamFacialScalp = 180,
    PhysicalExamFall = 181,
    PhysicalExamFemaleGu = 182,
    PhysicalExamFlu = 183,
    PhysicalExamFoot = 184,
    PhysicalExamGiBleeding = 185,
    PhysicalExamHand = 186,
    PhysicalExamHead = 187,
    PhysicalExamHeadache = 188,
    PhysicalExamHip = 189,
    PhysicalExamLowerBack = 190,
    PhysicalExamLowerExtremityInjury = 191,
    PhysicalExamLowerExtremityProblem = 192,
    PhysicalExamMvc = 193,
    PhysicalExamMaleGu = 194,
    PhysicalExamMiscellaneous = 195,
    PhysicalExamMouth = 196,
    PhysicalExamMultipleTrauma = 197,
    PhysicalExamNvd = 198,
    PhysicalExamNeckUpperBack = 199,
    PhysicalExamNeuro = 200,
    PhysicalExamNose = 201,
    PhysicalExamPalpitations = 202,
    PhysicalExamPediatricAbdominal = 203,
    PhysicalExamPediatricIllness = 204,
    PhysicalExamPediatricInjury = 205,
    PhysicalExamPediatricWheezing = 206,
    PhysicalExamPlantar = 207,
    PhysicalExamPregnancy = 208,
    PhysicalExamPsychSuicideOverdose = 209,
    PhysicalExamSeizure = 210,
    PhysicalExamShoulder = 211,
    PhysicalExamSkin = 212,
    PhysicalExamSyncope = 213,
    PhysicalExamTrunk = 214,
    PhysicalExamUpperExtremityInjury = 215,
    PhysicalExamUpperExtremityProblem = 216,
    PhysicianProgressNote = 217,
    PsychologicalAssessment = 218,
    RadiographicInterpretationUltrasound = 219,
    RadiologyInterpretation = 220,
    Reassessment = 221,
    SepsisScreening = 222,
    SkinAssessment = 223,
    SocialHistory = 224,
    SplintPlacementDetails = 225,
    UaCollectionDetails = 226,
    VitalSigns = 227,
}

export const QuestionnaireTitleApiEnumDisplayMap : { [key in QuestionnaireTitleApiEnum] : string } = {
    [QuestionnaireTitleApiEnum.AbdominalGuAssessment]: "AbdominalGuAssessment",
    [QuestionnaireTitleApiEnum.BloodDrawDetails]: "BloodDrawDetails",
    [QuestionnaireTitleApiEnum.CvsAssessment]: "CvsAssessment",
    [QuestionnaireTitleApiEnum.CadiacMonitorDetails]: "CadiacMonitorDetails",
    [QuestionnaireTitleApiEnum.CathFoleyDetails]: "CathFoleyDetails",
    [QuestionnaireTitleApiEnum.ChestAssessment]: "ChestAssessment",
    [QuestionnaireTitleApiEnum.ClinicalImpressionAbdominalPain]: "ClinicalImpressionAbdominalPain",
    [QuestionnaireTitleApiEnum.ClinicalImpressionAllergy]: "ClinicalImpressionAllergy",
    [QuestionnaireTitleApiEnum.ClinicalImpressionAlteredMentalState]: "ClinicalImpressionAlteredMentalState",
    [QuestionnaireTitleApiEnum.ClinicalImpressionAnimalBite]: "ClinicalImpressionAnimalBite",
    [QuestionnaireTitleApiEnum.ClinicalImpressionAssault]: "ClinicalImpressionAssault",
    [QuestionnaireTitleApiEnum.ClinicalImpressionAsthma]: "ClinicalImpressionAsthma",
    [QuestionnaireTitleApiEnum.ClinicalImpressionBurnOrSmokeInhalation]: "ClinicalImpressionBurnOrSmokeInhalation",
    [QuestionnaireTitleApiEnum.ClinicalImpressionChest]: "ClinicalImpressionChest",
    [QuestionnaireTitleApiEnum.ClinicalImpressionCriticalCare]: "ClinicalImpressionCriticalCare",
    [QuestionnaireTitleApiEnum.ClinicalImpressionDizziness]: "ClinicalImpressionDizziness",
    [QuestionnaireTitleApiEnum.ClinicalImpressionDyspnea]: "ClinicalImpressionDyspnea",
    [QuestionnaireTitleApiEnum.ClinicalImpressionEar]: "ClinicalImpressionEar",
    [QuestionnaireTitleApiEnum.ClinicalImpressionEye]: "ClinicalImpressionEye",
    [QuestionnaireTitleApiEnum.ClinicalImpressionFaceAndScalp]: "ClinicalImpressionFaceAndScalp",
    [QuestionnaireTitleApiEnum.ClinicalImpressionFall]: "ClinicalImpressionFall",
    [QuestionnaireTitleApiEnum.ClinicalImpressionFemaleGu]: "ClinicalImpressionFemaleGu",
    [QuestionnaireTitleApiEnum.ClinicalImpressionFlu]: "ClinicalImpressionFlu",
    [QuestionnaireTitleApiEnum.ClinicalImpressionFoot]: "ClinicalImpressionFoot",
    [QuestionnaireTitleApiEnum.ClinicalImpressionGiBleeding]: "ClinicalImpressionGiBleeding",
    [QuestionnaireTitleApiEnum.ClinicalImpressionHand]: "ClinicalImpressionHand",
    [QuestionnaireTitleApiEnum.ClinicalImpressionHead]: "ClinicalImpressionHead",
    [QuestionnaireTitleApiEnum.ClinicalImpressionHeadache]: "ClinicalImpressionHeadache",
    [QuestionnaireTitleApiEnum.ClinicalImpressionHip]: "ClinicalImpressionHip",
    [QuestionnaireTitleApiEnum.ClinicalImpressionLowerBack]: "ClinicalImpressionLowerBack",
    [QuestionnaireTitleApiEnum.ClinicalImpressionLowerExtremityInjury]: "ClinicalImpressionLowerExtremityInjury",
    [QuestionnaireTitleApiEnum.ClinicalImpressionLowerExtremityProblem]: "ClinicalImpressionLowerExtremityProblem",
    [QuestionnaireTitleApiEnum.ClinicalImpressionMvc]: "ClinicalImpressionMvc",
    [QuestionnaireTitleApiEnum.ClinicalImpressionMaleGu]: "ClinicalImpressionMaleGu",
    [QuestionnaireTitleApiEnum.ClinicalImpressionMiscellaneous]: "ClinicalImpressionMiscellaneous",
    [QuestionnaireTitleApiEnum.ClinicalImpressionMouth]: "ClinicalImpressionMouth",
    [QuestionnaireTitleApiEnum.ClinicalImpressionMultipleTrauma]: "ClinicalImpressionMultipleTrauma",
    [QuestionnaireTitleApiEnum.ClinicalImpressionNvd]: "ClinicalImpressionNvd",
    [QuestionnaireTitleApiEnum.ClinicalImpressionNeckUpperBack]: "ClinicalImpressionNeckUpperBack",
    [QuestionnaireTitleApiEnum.ClinicalImpressionNeuro]: "ClinicalImpressionNeuro",
    [QuestionnaireTitleApiEnum.ClinicalImpressionNose]: "ClinicalImpressionNose",
    [QuestionnaireTitleApiEnum.ClinicalImpressionPalpitations]: "ClinicalImpressionPalpitations",
    [QuestionnaireTitleApiEnum.ClinicalImpressionPediatricAbdominal]: "ClinicalImpressionPediatricAbdominal",
    [QuestionnaireTitleApiEnum.ClinicalImpressionPediatricIllness]: "ClinicalImpressionPediatricIllness",
    [QuestionnaireTitleApiEnum.ClinicalImpressionPediatricInjury]: "ClinicalImpressionPediatricInjury",
    [QuestionnaireTitleApiEnum.ClinicalImpressionPediatricWheezing]: "ClinicalImpressionPediatricWheezing",
    [QuestionnaireTitleApiEnum.ClinicalImpressionPlantar]: "ClinicalImpressionPlantar",
    [QuestionnaireTitleApiEnum.ClinicalImpressionPregnancy]: "ClinicalImpressionPregnancy",
    [QuestionnaireTitleApiEnum.ClinicalImpressionPsychSuicideOverdose]: "ClinicalImpressionPsychSuicideOverdose",
    [QuestionnaireTitleApiEnum.ClinicalImpressionSeizure]: "ClinicalImpressionSeizure",
    [QuestionnaireTitleApiEnum.ClinicalImpressionShoulder]: "ClinicalImpressionShoulder",
    [QuestionnaireTitleApiEnum.ClinicalImpressionSkin]: "ClinicalImpressionSkin",
    [QuestionnaireTitleApiEnum.ClinicalImpressionSyncope]: "ClinicalImpressionSyncope",
    [QuestionnaireTitleApiEnum.ClinicalImpressionTrunk]: "ClinicalImpressionTrunk",
    [QuestionnaireTitleApiEnum.ClinicalImpressionUpperExtremitiesInjury]: "ClinicalImpressionUpperExtremitiesInjury",
    [QuestionnaireTitleApiEnum.ClinicalImpressionUpperExtremityProblems]: "ClinicalImpressionUpperExtremityProblems",
    [QuestionnaireTitleApiEnum.DomesticViolenceScreening]: "DomesticViolenceScreening",
    [QuestionnaireTitleApiEnum.EentAssessment]: "EentAssessment",
    [QuestionnaireTitleApiEnum.EkgDetails]: "EkgDetails",
    [QuestionnaireTitleApiEnum.EkgFindingsAndImpressions]: "EkgFindingsAndImpressions",
    [QuestionnaireTitleApiEnum.ExtremetiesAssessment]: "ExtremetiesAssessment",
    [QuestionnaireTitleApiEnum.FallScreening]: "FallScreening",
    [QuestionnaireTitleApiEnum.FamilyMemberHealthHistory]: "FamilyMemberHealthHistory",
    [QuestionnaireTitleApiEnum.FunctionalNutritionalAssessment]: "FunctionalNutritionalAssessment",
    [QuestionnaireTitleApiEnum.FunctionalNutritionalScreening]: "FunctionalNutritionalScreening",
    [QuestionnaireTitleApiEnum.GeneralAssessment]: "GeneralAssessment",
    [QuestionnaireTitleApiEnum.HeadNeckAssessment]: "HeadNeckAssessment",
    [QuestionnaireTitleApiEnum.HistoryOfPresentIllnessAbdominal]: "HistoryOfPresentIllnessAbdominal",
    [QuestionnaireTitleApiEnum.HistoryOfPresentIllnessAllergies]: "HistoryOfPresentIllnessAllergies",
    [QuestionnaireTitleApiEnum.HistoryOfPresentIllnessAlteredMentalState]: "HistoryOfPresentIllnessAlteredMentalState",
    [QuestionnaireTitleApiEnum.HistoryOfPresentIllnessAnimalBite]: "HistoryOfPresentIllnessAnimalBite",
    [QuestionnaireTitleApiEnum.HistoryOfPresentIllnessAssault]: "HistoryOfPresentIllnessAssault",
    [QuestionnaireTitleApiEnum.HistoryOfPresentIllnessAsthma]: "HistoryOfPresentIllnessAsthma",
    [QuestionnaireTitleApiEnum.HistoryOfPresentIllnessBurnInhalation]: "HistoryOfPresentIllnessBurnInhalation",
    [QuestionnaireTitleApiEnum.HistoryOfPresentIllnessCardiopulmonary]: "HistoryOfPresentIllnessCardiopulmonary",
    [QuestionnaireTitleApiEnum.HistoryOfPresentIllnessChest]: "HistoryOfPresentIllnessChest",
    [QuestionnaireTitleApiEnum.HistoryOfPresentIllnessCriticalCare]: "HistoryOfPresentIllnessCriticalCare",
    [QuestionnaireTitleApiEnum.HistoryOfPresentIllnessDizziness]: "HistoryOfPresentIllnessDizziness",
    [QuestionnaireTitleApiEnum.HistoryOfPresentIllnessDyspnea]: "HistoryOfPresentIllnessDyspnea",
    [QuestionnaireTitleApiEnum.HistoryOfPresentIllnessEar]: "HistoryOfPresentIllnessEar",
    [QuestionnaireTitleApiEnum.HistoryOfPresentIllnessEye]: "HistoryOfPresentIllnessEye",
    [QuestionnaireTitleApiEnum.HistoryOfPresentIllnessFaceScalp]: "HistoryOfPresentIllnessFaceScalp",
    [QuestionnaireTitleApiEnum.HistoryOfPresentIllnessFall]: "HistoryOfPresentIllnessFall",
    [QuestionnaireTitleApiEnum.HistoryOfPresentIllnessFemaleGu]: "HistoryOfPresentIllnessFemaleGu",
    [QuestionnaireTitleApiEnum.HistoryOfPresentIllnessFlu]: "HistoryOfPresentIllnessFlu",
    [QuestionnaireTitleApiEnum.HistoryOfPresentIllnessFoot]: "HistoryOfPresentIllnessFoot",
    [QuestionnaireTitleApiEnum.HistoryOfPresentIllnessGiBleeding]: "HistoryOfPresentIllnessGiBleeding",
    [QuestionnaireTitleApiEnum.HistoryOfPresentIllnessHand]: "HistoryOfPresentIllnessHand",
    [QuestionnaireTitleApiEnum.HistoryOfPresentIllnessHead]: "HistoryOfPresentIllnessHead",
    [QuestionnaireTitleApiEnum.HistoryOfPresentIllnessHeadache]: "HistoryOfPresentIllnessHeadache",
    [QuestionnaireTitleApiEnum.HistoryOfPresentIllnessHip]: "HistoryOfPresentIllnessHip",
    [QuestionnaireTitleApiEnum.HistoryOfPresentIllnessLowerBack]: "HistoryOfPresentIllnessLowerBack",
    [QuestionnaireTitleApiEnum.HistoryOfPresentIllnessLowerExtremityInjury]: "HistoryOfPresentIllnessLowerExtremityInjury",
    [QuestionnaireTitleApiEnum.HistoryOfPresentIllnessLowerExtremityProblem]: "HistoryOfPresentIllnessLowerExtremityProblem",
    [QuestionnaireTitleApiEnum.HistoryOfPresentIllnessMvc]: "HistoryOfPresentIllnessMvc",
    [QuestionnaireTitleApiEnum.HistoryOfPresentIllnessMaleGu]: "HistoryOfPresentIllnessMaleGu",
    [QuestionnaireTitleApiEnum.HistoryOfPresentIllnessMiscellaneous]: "HistoryOfPresentIllnessMiscellaneous",
    [QuestionnaireTitleApiEnum.HistoryOfPresentIllnessMouth]: "HistoryOfPresentIllnessMouth",
    [QuestionnaireTitleApiEnum.HistoryOfPresentIllnessMultipleTrauma]: "HistoryOfPresentIllnessMultipleTrauma",
    [QuestionnaireTitleApiEnum.HistoryOfPresentIllnessNvd]: "HistoryOfPresentIllnessNvd",
    [QuestionnaireTitleApiEnum.HistoryOfPresentIllnessNeckUpperBack]: "HistoryOfPresentIllnessNeckUpperBack",
    [QuestionnaireTitleApiEnum.HistoryOfPresentIllnessNeuro]: "HistoryOfPresentIllnessNeuro",
    [QuestionnaireTitleApiEnum.HistoryOfPresentIllnessNose]: "HistoryOfPresentIllnessNose",
    [QuestionnaireTitleApiEnum.HistoryOfPresentIllnessPalpitations]: "HistoryOfPresentIllnessPalpitations",
    [QuestionnaireTitleApiEnum.HistoryOfPresentIllnessPediatricAbdominal]: "HistoryOfPresentIllnessPediatricAbdominal",
    [QuestionnaireTitleApiEnum.HistoryOfPresentIllnessPediatricIllness]: "HistoryOfPresentIllnessPediatricIllness",
    [QuestionnaireTitleApiEnum.HistoryOfPresentIllnessPediatricInjury]: "HistoryOfPresentIllnessPediatricInjury",
    [QuestionnaireTitleApiEnum.HistoryOfPresentIllnessPediatricWheezing]: "HistoryOfPresentIllnessPediatricWheezing",
    [QuestionnaireTitleApiEnum.HistoryOfPresentIllnessPlantar]: "HistoryOfPresentIllnessPlantar",
    [QuestionnaireTitleApiEnum.HistoryOfPresentIllnessPregnancy]: "HistoryOfPresentIllnessPregnancy",
    [QuestionnaireTitleApiEnum.HistoryOfPresentIllnessPsychSuicideOverdose]: "HistoryOfPresentIllnessPsychSuicideOverdose",
    [QuestionnaireTitleApiEnum.HistoryOfPresentIllnessSeizure]: "HistoryOfPresentIllnessSeizure",
    [QuestionnaireTitleApiEnum.HistoryOfPresentIllnessShoulder]: "HistoryOfPresentIllnessShoulder",
    [QuestionnaireTitleApiEnum.HistoryOfPresentIllnessSkin]: "HistoryOfPresentIllnessSkin",
    [QuestionnaireTitleApiEnum.HistoryOfPresentIllnessSyncope]: "HistoryOfPresentIllnessSyncope",
    [QuestionnaireTitleApiEnum.HistoryOfPresentIllnessTrunk]: "HistoryOfPresentIllnessTrunk",
    [QuestionnaireTitleApiEnum.HistoryOfPresentIllnessUpperExtemityInjury]: "HistoryOfPresentIllnessUpperExtemityInjury",
    [QuestionnaireTitleApiEnum.HistoryOfPresentIllnessUpperExtemityProblem]: "HistoryOfPresentIllnessUpperExtemityProblem",
    [QuestionnaireTitleApiEnum.IvDetails]: "IvDetails",
    [QuestionnaireTitleApiEnum.InfectiousDiseaseScreening]: "InfectiousDiseaseScreening",
    [QuestionnaireTitleApiEnum.MentalHealthScreening]: "MentalHealthScreening",
    [QuestionnaireTitleApiEnum.NeurologicalAssessment]: "NeurologicalAssessment",
    [QuestionnaireTitleApiEnum.NgTubeDetails]: "NgTubeDetails",
    [QuestionnaireTitleApiEnum.NurseSecondaryAssessmentAbdominalPainNvd]: "NurseSecondaryAssessmentAbdominalPainNvd",
    [QuestionnaireTitleApiEnum.NurseSecondaryAssessmentAlcoholIntoxSubstanceAbuse]: "NurseSecondaryAssessmentAlcoholIntoxSubstanceAbuse",
    [QuestionnaireTitleApiEnum.NurseSecondaryAssessmentAllergy]: "NurseSecondaryAssessmentAllergy",
    [QuestionnaireTitleApiEnum.NurseSecondaryAssessmentAlteredMentalStatus]: "NurseSecondaryAssessmentAlteredMentalStatus",
    [QuestionnaireTitleApiEnum.NurseSecondaryAssessmentAnimalBite]: "NurseSecondaryAssessmentAnimalBite",
    [QuestionnaireTitleApiEnum.NurseSecondaryAssessmentAssault]: "NurseSecondaryAssessmentAssault",
    [QuestionnaireTitleApiEnum.NurseSecondaryAssessmentBack]: "NurseSecondaryAssessmentBack",
    [QuestionnaireTitleApiEnum.NurseSecondaryAssessmentBurnInhilation]: "NurseSecondaryAssessmentBurnInhilation",
    [QuestionnaireTitleApiEnum.NurseSecondaryAssessmentChest]: "NurseSecondaryAssessmentChest",
    [QuestionnaireTitleApiEnum.NurseSecondaryAssessmentDizziness]: "NurseSecondaryAssessmentDizziness",
    [QuestionnaireTitleApiEnum.NurseSecondaryAssessmentDyspneaAsthma]: "NurseSecondaryAssessmentDyspneaAsthma",
    [QuestionnaireTitleApiEnum.NurseSecondaryAssessmentEarAndNose]: "NurseSecondaryAssessmentEarAndNose",
    [QuestionnaireTitleApiEnum.NurseSecondaryAssessmentEye]: "NurseSecondaryAssessmentEye",
    [QuestionnaireTitleApiEnum.NurseSecondaryAssessmentFemaleGu]: "NurseSecondaryAssessmentFemaleGu",
    [QuestionnaireTitleApiEnum.NurseSecondaryAssessmentFlu]: "NurseSecondaryAssessmentFlu",
    [QuestionnaireTitleApiEnum.NurseSecondaryAssessmentGeneralMedicine]: "NurseSecondaryAssessmentGeneralMedicine",
    [QuestionnaireTitleApiEnum.NurseSecondaryAssessmentGeriatricFever]: "NurseSecondaryAssessmentGeriatricFever",
    [QuestionnaireTitleApiEnum.NurseSecondaryAssessmentHead]: "NurseSecondaryAssessmentHead",
    [QuestionnaireTitleApiEnum.NurseSecondaryAssessmentHeadache]: "NurseSecondaryAssessmentHeadache",
    [QuestionnaireTitleApiEnum.NurseSecondaryAssessmentLowerExtremities]: "NurseSecondaryAssessmentLowerExtremities",
    [QuestionnaireTitleApiEnum.NurseSecondaryAssessmentLowerExtremitiesProblem]: "NurseSecondaryAssessmentLowerExtremitiesProblem",
    [QuestionnaireTitleApiEnum.NurseSecondaryAssessmentMvc]: "NurseSecondaryAssessmentMvc",
    [QuestionnaireTitleApiEnum.NurseSecondaryAssessmentMaleGu]: "NurseSecondaryAssessmentMaleGu",
    [QuestionnaireTitleApiEnum.NurseSecondaryAssessmentMouth]: "NurseSecondaryAssessmentMouth",
    [QuestionnaireTitleApiEnum.NurseSecondaryAssessmentNeuro]: "NurseSecondaryAssessmentNeuro",
    [QuestionnaireTitleApiEnum.NurseSecondaryAssessmentOverdose]: "NurseSecondaryAssessmentOverdose",
    [QuestionnaireTitleApiEnum.NurseSecondaryAssessmentPediatricAbdominal]: "NurseSecondaryAssessmentPediatricAbdominal",
    [QuestionnaireTitleApiEnum.NurseSecondaryAssessmentPediatricFever]: "NurseSecondaryAssessmentPediatricFever",
    [QuestionnaireTitleApiEnum.NurseSecondaryAssessmentPediatricIllness]: "NurseSecondaryAssessmentPediatricIllness",
    [QuestionnaireTitleApiEnum.NurseSecondaryAssessmentPediatricInjury]: "NurseSecondaryAssessmentPediatricInjury",
    [QuestionnaireTitleApiEnum.NurseSecondaryAssessmentPediatricWheezing]: "NurseSecondaryAssessmentPediatricWheezing",
    [QuestionnaireTitleApiEnum.NurseSecondaryAssessmentPregnancy]: "NurseSecondaryAssessmentPregnancy",
    [QuestionnaireTitleApiEnum.NurseSecondaryAssessmentPsych]: "NurseSecondaryAssessmentPsych",
    [QuestionnaireTitleApiEnum.NurseSecondaryAssessmentSeizure]: "NurseSecondaryAssessmentSeizure",
    [QuestionnaireTitleApiEnum.NurseSecondaryAssessmentSkin]: "NurseSecondaryAssessmentSkin",
    [QuestionnaireTitleApiEnum.NurseSecondaryAssessmentUpperExtremitiesInjury]: "NurseSecondaryAssessmentUpperExtremitiesInjury",
    [QuestionnaireTitleApiEnum.NurseSecondaryAssessmentUpperExtremityProblem]: "NurseSecondaryAssessmentUpperExtremityProblem",
    [QuestionnaireTitleApiEnum.NurseSecondaryAssessmentUpperRespiratory]: "NurseSecondaryAssessmentUpperRespiratory",
    [QuestionnaireTitleApiEnum.NurseTriage]: "NurseTriage",
    [QuestionnaireTitleApiEnum.PastMedicalHistory]: "PastMedicalHistory",
    [QuestionnaireTitleApiEnum.PastSurgicalHistory]: "PastSurgicalHistory",
    [QuestionnaireTitleApiEnum.PatientDischargeQuestionnaire]: "PatientDischargeQuestionnaire",
    [QuestionnaireTitleApiEnum.PediatricMentalHealthScreening]: "PediatricMentalHealthScreening",
    [QuestionnaireTitleApiEnum.PhysicalExamAbdominal]: "PhysicalExamAbdominal",
    [QuestionnaireTitleApiEnum.PhysicalExamAllergies]: "PhysicalExamAllergies",
    [QuestionnaireTitleApiEnum.PhysicalExamAlteredMentalState]: "PhysicalExamAlteredMentalState",
    [QuestionnaireTitleApiEnum.PhysicalExamAnimalBite]: "PhysicalExamAnimalBite",
    [QuestionnaireTitleApiEnum.PhysicalExamAssault]: "PhysicalExamAssault",
    [QuestionnaireTitleApiEnum.PhysicalExamAsthma]: "PhysicalExamAsthma",
    [QuestionnaireTitleApiEnum.PhysicalExamBurnSmokeInhilation]: "PhysicalExamBurnSmokeInhilation",
    [QuestionnaireTitleApiEnum.PhysicalExamCardiopulmonaryAssessment]: "PhysicalExamCardiopulmonaryAssessment",
    [QuestionnaireTitleApiEnum.PhysicalExamChest]: "PhysicalExamChest",
    [QuestionnaireTitleApiEnum.PhysicalExamCriticalCare]: "PhysicalExamCriticalCare",
    [QuestionnaireTitleApiEnum.PhysicalExamDizziness]: "PhysicalExamDizziness",
    [QuestionnaireTitleApiEnum.PhysicalExamDyspnea]: "PhysicalExamDyspnea",
    [QuestionnaireTitleApiEnum.PhysicalExamEar]: "PhysicalExamEar",
    [QuestionnaireTitleApiEnum.PhysicalExamEye]: "PhysicalExamEye",
    [QuestionnaireTitleApiEnum.PhysicalExamFacialScalp]: "PhysicalExamFacialScalp",
    [QuestionnaireTitleApiEnum.PhysicalExamFall]: "PhysicalExamFall",
    [QuestionnaireTitleApiEnum.PhysicalExamFemaleGu]: "PhysicalExamFemaleGu",
    [QuestionnaireTitleApiEnum.PhysicalExamFlu]: "PhysicalExamFlu",
    [QuestionnaireTitleApiEnum.PhysicalExamFoot]: "PhysicalExamFoot",
    [QuestionnaireTitleApiEnum.PhysicalExamGiBleeding]: "PhysicalExamGiBleeding",
    [QuestionnaireTitleApiEnum.PhysicalExamHand]: "PhysicalExamHand",
    [QuestionnaireTitleApiEnum.PhysicalExamHead]: "PhysicalExamHead",
    [QuestionnaireTitleApiEnum.PhysicalExamHeadache]: "PhysicalExamHeadache",
    [QuestionnaireTitleApiEnum.PhysicalExamHip]: "PhysicalExamHip",
    [QuestionnaireTitleApiEnum.PhysicalExamLowerBack]: "PhysicalExamLowerBack",
    [QuestionnaireTitleApiEnum.PhysicalExamLowerExtremityInjury]: "PhysicalExamLowerExtremityInjury",
    [QuestionnaireTitleApiEnum.PhysicalExamLowerExtremityProblem]: "PhysicalExamLowerExtremityProblem",
    [QuestionnaireTitleApiEnum.PhysicalExamMvc]: "PhysicalExamMvc",
    [QuestionnaireTitleApiEnum.PhysicalExamMaleGu]: "PhysicalExamMaleGu",
    [QuestionnaireTitleApiEnum.PhysicalExamMiscellaneous]: "PhysicalExamMiscellaneous",
    [QuestionnaireTitleApiEnum.PhysicalExamMouth]: "PhysicalExamMouth",
    [QuestionnaireTitleApiEnum.PhysicalExamMultipleTrauma]: "PhysicalExamMultipleTrauma",
    [QuestionnaireTitleApiEnum.PhysicalExamNvd]: "PhysicalExamNvd",
    [QuestionnaireTitleApiEnum.PhysicalExamNeckUpperBack]: "PhysicalExamNeckUpperBack",
    [QuestionnaireTitleApiEnum.PhysicalExamNeuro]: "PhysicalExamNeuro",
    [QuestionnaireTitleApiEnum.PhysicalExamNose]: "PhysicalExamNose",
    [QuestionnaireTitleApiEnum.PhysicalExamPalpitations]: "PhysicalExamPalpitations",
    [QuestionnaireTitleApiEnum.PhysicalExamPediatricAbdominal]: "PhysicalExamPediatricAbdominal",
    [QuestionnaireTitleApiEnum.PhysicalExamPediatricIllness]: "PhysicalExamPediatricIllness",
    [QuestionnaireTitleApiEnum.PhysicalExamPediatricInjury]: "PhysicalExamPediatricInjury",
    [QuestionnaireTitleApiEnum.PhysicalExamPediatricWheezing]: "PhysicalExamPediatricWheezing",
    [QuestionnaireTitleApiEnum.PhysicalExamPlantar]: "PhysicalExamPlantar",
    [QuestionnaireTitleApiEnum.PhysicalExamPregnancy]: "PhysicalExamPregnancy",
    [QuestionnaireTitleApiEnum.PhysicalExamPsychSuicideOverdose]: "PhysicalExamPsychSuicideOverdose",
    [QuestionnaireTitleApiEnum.PhysicalExamSeizure]: "PhysicalExamSeizure",
    [QuestionnaireTitleApiEnum.PhysicalExamShoulder]: "PhysicalExamShoulder",
    [QuestionnaireTitleApiEnum.PhysicalExamSkin]: "PhysicalExamSkin",
    [QuestionnaireTitleApiEnum.PhysicalExamSyncope]: "PhysicalExamSyncope",
    [QuestionnaireTitleApiEnum.PhysicalExamTrunk]: "PhysicalExamTrunk",
    [QuestionnaireTitleApiEnum.PhysicalExamUpperExtremityInjury]: "PhysicalExamUpperExtremityInjury",
    [QuestionnaireTitleApiEnum.PhysicalExamUpperExtremityProblem]: "PhysicalExamUpperExtremityProblem",
    [QuestionnaireTitleApiEnum.PhysicianProgressNote]: "PhysicianProgressNote",
    [QuestionnaireTitleApiEnum.PsychologicalAssessment]: "PsychologicalAssessment",
    [QuestionnaireTitleApiEnum.RadiographicInterpretationUltrasound]: "RadiographicInterpretationUltrasound",
    [QuestionnaireTitleApiEnum.RadiologyInterpretation]: "RadiologyInterpretation",
    [QuestionnaireTitleApiEnum.Reassessment]: "Reassessment",
    [QuestionnaireTitleApiEnum.SepsisScreening]: "SepsisScreening",
    [QuestionnaireTitleApiEnum.SkinAssessment]: "SkinAssessment",
    [QuestionnaireTitleApiEnum.SocialHistory]: "SocialHistory",
    [QuestionnaireTitleApiEnum.SplintPlacementDetails]: "SplintPlacementDetails",
    [QuestionnaireTitleApiEnum.UaCollectionDetails]: "UaCollectionDetails",
    [QuestionnaireTitleApiEnum.VitalSigns]: "VitalSigns",
}
