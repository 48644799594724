/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
export enum OrderRequestStatusApiEnum {
    Draft = 0,
    Active = 1,
    OnHold = 2,
    Revoked = 3,
    Completed = 4,
    EnteredInError = 5,
    Unknown = 6,
}

export const OrderRequestStatusApiEnumDisplayMap : { [key in OrderRequestStatusApiEnum] : string } = {
    [OrderRequestStatusApiEnum.Draft]: "Draft",
    [OrderRequestStatusApiEnum.Active]: "Active",
    [OrderRequestStatusApiEnum.OnHold]: "OnHold",
    [OrderRequestStatusApiEnum.Revoked]: "Revoked",
    [OrderRequestStatusApiEnum.Completed]: "Completed",
    [OrderRequestStatusApiEnum.EnteredInError]: "EnteredInError",
    [OrderRequestStatusApiEnum.Unknown]: "Unknown",
}
