import { HttpResponse } from '@angular/common/http';
import { inject } from '@angular/core';
import { ResolveFn } from '@angular/router';
import { UserSettingsBaseDataApiModel } from '../generated-models';
import { AppLayoutService, NavigationLinkSection } from '../layout/app-layout/app-layout.service';
import { UserSettingsService } from './user-settings.service';

export const settingsResolver: ResolveFn<HttpResponse<UserSettingsBaseDataApiModel>> = () => {
	const navigationLinkSections: NavigationLinkSection[] = [
		{
			navigationLinks: [
				{
					name: 'Detail',
					routerLink: 'detail',
					icon: 'info'
				},
				{
					name: 'MFA',
					routerLink: 'mfa',
					icon: 'phonelink_lock'
				},
				{
					name: 'Password',
					routerLink: 'password',
					icon: 'password'
				}
			]
		} as NavigationLinkSection
	];

	inject(AppLayoutService).navigationLinkSections.set(navigationLinkSections);
	inject(AppLayoutService).openByDefault.set(true);

	return inject(UserSettingsService).getBaseData();
};
