/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
export enum NarrativeStatusEnumApiModel {
    Generated = 0,
    Extensions = 1,
    Additional = 2,
    Empty = 3,
}

export const NarrativeStatusEnumApiModelDisplayMap : { [key in NarrativeStatusEnumApiModel] : string } = {
    [NarrativeStatusEnumApiModel.Generated]: "Generated",
    [NarrativeStatusEnumApiModel.Extensions]: "Extensions",
    [NarrativeStatusEnumApiModel.Additional]: "Additional",
    [NarrativeStatusEnumApiModel.Empty]: "Empty",
}
