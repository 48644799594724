import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component, effect, input, OnInit, signal } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatFormFieldModule } from '@angular/material/form-field';
import { BaseInputFormFieldComponent } from '../base-input-form-field';

@Component({
	standalone: true,
	changeDetection: ChangeDetectionStrategy.OnPush,
	selector: 'boolean-text-select-input-form-field',
	templateUrl: './boolean-text-select-input-form-field.component.html',
	styleUrl: './boolean-text-select-input-form-field.component.scss',
	imports: [CommonModule, FormsModule, ReactiveFormsModule, MatFormFieldModule, MatButtonModule]
})
export class BooleanTextSelectInputFormFieldComponent extends BaseInputFormFieldComponent implements OnInit {
	constructor() {
		super('A selection');

		effect(
			() => {
				const state = this.state();
				this.formCtrl.setValue(state);
			},
			{ allowSignalWrites: true }
		);
	}

	public previousState: boolean | null = null;

	public state = signal<boolean | null>(null);

	public readonly = input<boolean>(false);

	public toggleState(): void {
		if (!this.readonly()) {
			const state = this.state();
			if (this.previousState != null && !this.previousState) {
				this.state.set(null);
			} else {
				this.state.set(!state);
			}

			this.previousState = this.state();
		}
	}

	public ngOnInit(): void {
		this.state.set(this.formCtrl.value);
	}
}
