/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
export enum OrderPriorityApiEnum {
    Routine = 0,
    Urgent = 1,
    ASAP = 2,
    STAT = 3,
}

export const OrderPriorityApiEnumDisplayMap : { [key in OrderPriorityApiEnum] : string } = {
    [OrderPriorityApiEnum.Routine]: "Routine",
    [OrderPriorityApiEnum.Urgent]: "Urgent",
    [OrderPriorityApiEnum.ASAP]: "ASAP",
    [OrderPriorityApiEnum.STAT]: "STAT",
}
