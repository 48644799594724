export function ageFormatter(value: string | null | undefined, defaultValue: string = '--'): string {
	if (!value || value === null) {
		return defaultValue;
	}

	const dob = value ? new Date(value) : new Date();
	const diffMs = Date.now() - dob.getTime();
	const ageDt = new Date(diffMs);
	const ageInYears = Math.abs(ageDt.getUTCFullYear() - 1970);

	return String(ageInYears);
}
