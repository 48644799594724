import { HttpClient } from '@angular/common/http';
import { Injectable, signal } from '@angular/core';
import { BaseService } from '../../shared';
import { NotificationService } from '../notifications';

export interface NavigationLink {
	name: string;
	routerLink: string;
	icon: string;
}

export interface NavigationLinkSection {
	sectionHeader: string | null | undefined;
	navigationLinks: NavigationLink[];
}

@Injectable({
	providedIn: 'root'
})
export class AppLayoutService extends BaseService<void> {
	constructor(_httpClient: HttpClient, _notificationService: NotificationService) {
		super(null, _httpClient, _notificationService);
	}

	public navigationLinkSections = signal<NavigationLinkSection[]>([]);
	public openByDefault = signal<boolean>(false);
}
