import { BooleanInput, coerceBooleanProperty } from '@angular/cdk/coercion';
import { CommonModule } from '@angular/common';
import { Component, EventEmitter, Input, Output } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatCardModule } from '@angular/material/card';
import { MatIcon } from '@angular/material/icon';

export interface HeaderButtonOptions {
	icon: string;
	color?: 'warn' | 'secondary' | 'primary';
}

@Component({
	standalone: true,
	selector: 'action-card',
	templateUrl: './action-card.component.html',
	styleUrl: './action-card.component.scss',
	imports: [CommonModule, MatCardModule, MatButtonModule, MatIcon]
})
export class ActionCardComponent {
	private _disabled: boolean = false;
	private _noFooterContent: boolean = false;

	@Input()
	public title: string | null = null;

	@Input()
	set disabled(value: BooleanInput) {
		this._disabled = coerceBooleanProperty(value);
	}

	@Input() headerButtonOptions: HeaderButtonOptions | null = null;

	@Output() headerButtonClicked: EventEmitter<void> = new EventEmitter();

	get disabled(): boolean {
		return this._disabled;
	}

	@Input()
	set noFooterContent(value: BooleanInput) {
		this._noFooterContent = coerceBooleanProperty(value);
	}

	get noFooterContent(): boolean {
		return this._noFooterContent;
	}
}
