/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
export enum AccountStatusApiEnum {
    Inactive = 0,
    OnHold = 1,
    EnteredInError = 2,
    Active = 3,
    Unknown = 4,
}

export const AccountStatusApiEnumDisplayMap : { [key in AccountStatusApiEnum] : string } = {
    [AccountStatusApiEnum.Inactive]: "Inactive",
    [AccountStatusApiEnum.OnHold]: "OnHold",
    [AccountStatusApiEnum.EnteredInError]: "EnteredInError",
    [AccountStatusApiEnum.Active]: "Active",
    [AccountStatusApiEnum.Unknown]: "Unknown",
}
